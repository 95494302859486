// App.js
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import html2canvas from 'html2canvas';
import userData from './data/users.json';
import companyData from './data/company.json';
import './App.css';

function UserCard() {
  const { username } = useParams();
  const user = userData[username];
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    if (user) {
      document.title = `${user.name}'s Card`;
    }
    setLoading(false);
  }, [user]);

  if (!user) {
    return <div className="not-found">User not found</div>;
  }

  const handleImageError = () => {
    setImageError(true);
  };
  const handleSaveContact = () => {
    const vcard = `BEGIN:VCARD
VERSION:3.0
FN:${user.name}
TITLE:${user.title}
${user.phone ? `TEL:${user.phone}` : ''}
TEL;TYPE=WORK:${companyData.phone}
EMAIL:${user.email}
URL:${companyData.website}
END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${user.name}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const takeScreenshot = () => {
    html2canvas(cardRef.current, {
      scale: 2,
      backgroundColor: null,
      logging: false,
      useCORS: true
    }).then(canvas => {
      const link = document.createElement('a');
      link.download = `${user.name}_card.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    });
  };

  const shareCard = () => {
    if (navigator.share) {
      navigator.share({
        title: `${user.name}'s Business Card`,
        text: `Check out ${user.name}'s business card!`,
        url: window.location.href
      }).catch(console.error);
    } else {
      alert('Sharing is not supported on this browser, but you can copy the URL to share.');
    }
  };


  return (
    <motion.div
      className="user-card"
      ref={cardRef}
      key="card"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div className="card-header">
        <motion.div
          className="avatar"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, type: "spring", stiffness: 260, damping: 20 }}
        >
          {!imageError ? (
            <img 
              src={`/images/${username}.jpg`} 
              alt={user.name} 
              onError={handleImageError}
            />
          ) : (
            user.name.charAt(0)
          )}
        </motion.div>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          {user.name}
        </motion.h1>
        <motion.p
          className="title"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          {user.title}
        </motion.p>
      </div>
      <motion.div
        className="contact-info"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        {user.phone && (
          <a href={`tel:${user.phone}`} className="info-item">
            <i className="fas fa-mobile-alt"></i> {user.phone}
          </a>
        )}
        <a href={`tel:${companyData.phone}`} className="info-item">
          <i className="fas fa-phone"></i> {companyData.phone}
        </a>
        <a href={`mailto:${user.email}`} className="info-item">
          <i className="fas fa-envelope"></i> {user.email}
        </a>
        {user.linkedin && (
          <a href={user.linkedin} target="_blank" rel="noopener noreferrer" className="info-item">
            <i className="fab fa-linkedin"></i> LinkedIn
          </a>
        )}
      </motion.div>
      <motion.div
        className="social-icons"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
      >
        <a href={companyData.website} target="_blank" rel="noopener noreferrer" className="icon-item" title="Company Website">
          <i className="fas fa-globe"></i>
        </a>
        <a href={companyData.linkedin} target="_blank" rel="noopener noreferrer" className="icon-item" title="Company LinkedIn">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href={companyData.mapsUrl} target="_blank" rel="noopener noreferrer" className="icon-item" title="Company Location">
          <i className="fas fa-map-marker-alt"></i>
        </a>
        <a href={`mailto:${companyData.email}`} className="icon-item" title="Company Email">
          <i className="fas fa-envelope"></i>
        </a>
        <a href={companyData.contactFormUrl} target="_blank" rel="noopener noreferrer" className="icon-item" title="Contact Form">
          <i className="fas fa-paper-plane"></i>
        </a>
        <button onClick={takeScreenshot} className="icon-item" title="Take a screenshot">
          <i className="fas fa-camera"></i>
        </button>
        <button onClick={shareCard} className="icon-item" title="Share this card">
          <i className="fas fa-share-alt"></i>
        </button>
      </motion.div>
      <motion.button
        onClick={handleSaveContact}
        className="save-contact"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7 }}
      >
        <i className="fas fa-user-plus"></i> Save Contact
      </motion.button>
      
      <footer className="card-footer">
  <img src="/images/logo.png" alt="Imba Cloud Logo" className="footer-logo" />
</footer>
    </motion.div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/:username" element={<UserCard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;